div.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &.small {
    width: 46px;
    height: 46px;
    padding: 1px;
  }
}
