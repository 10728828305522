.form-input-container {
  position: relative;
  .form-input-number {
    border: 1px solid #dddddd;
    border-radius: 8px;
    position: relative;
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    color: #4b4b55;
    font-weight: 500;
    font-style: normal;
    outline: none;
    width: 100%;
    text-align: left;

    &.error {
      border: 1px solid rgba(198, 23, 23, 0.5);
    }

    &::placeholder {
      color: rgba(75, 75, 85, 0.5);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .arrow-up {
    position: absolute;
    right: 4px;
    top: 8px;
    z-index: 3;
  }

  .arrow-down {
    position: absolute;
    right: 4px;
    bottom: 8px;
    z-index: 3;

    &.disabled {
      opacity: 0.5;
    }
  }

  .textbox-label {
    position: absolute;
    font-size: 11px;
    color: rgba(75, 75, 85, 0.5);
    margin-left: 15px;
    margin-top: 7px;
    z-index: 2;
    &.empty {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      top: 9px;
    }
  }

  .checkbox-label {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    font-style: normal;
  }

  .form-input-checkbox {
    visibility: hidden; /* <-- Hide the default checkbox. The rest is to hide and allow tabbing, which display:none prevents */
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    overflow: hidden;
  }

  .checkbox-span {
    height: 20px;
    width: 20px;
    border: 1px solid #ddd;
    display: inline-block;
    border-radius: 6px;
  }

  .checked-state {
    svg {
      left: 2px;
      top: 2px;
      position: absolute;
    }
  }

  &.error {
    svg {
      position: absolute;
      color: #c61717;
      position: absolute;
      right: 12px;
      top: 13px;
      font-size: 1.5rem;
    }
  }
}
