.pdp-details-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  &.expanded {
    .card-wrapper {
      .pdp-details {
        .pdp-artwork {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .pdp-options {
          visibility: hidden;
        }
      }
    }
  }
  .card-wrapper {
    position: relative;
    .pdp-details {
      height: 100%;
      position: relative;
      .pdp-artwork {
        width: 55%;
        position: relative;
      }
      .pdp-options {
        overflow: auto;
        width: 45%;
        padding: 60px 60px 34px 60px;

        .product-quantity {
          background: #ffffff;
          border: 1px solid #dddddd;
          box-sizing: border-box;
          box-shadow: 0px 1px 3px rgba(75, 75, 85, 0.1);
          border-radius: 8px;
          width: 60px;
          height: 44px;
          text-align: center;
          margin-left: 16px;
        }
      }
    }
  }
}

.productInfoContainer {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .pdp-details-container {
    padding: 0;
    max-height: none;
    .card-wrapper {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      .pdp-details {
        border-radius: 0;
        .pdp-artwork {
          width: 100%;
          padding-bottom: 31px;
        }
        .pdp-options {
          width: 100%;
          padding: 30px;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .pdp-details-container {
    padding: 0 20px;
    .card-wrapper {
      position: relative;
      .pdp-details {
        height: 100%;
        position: relative;
        .pdp-artwork {
          width: 55%;
          position: relative;
        }
        .pdp-options {
          overflow: auto;
          width: 45%;
          padding: 30px;
        }
      }
    }
  }
}
