.cart-indicator-icon {
  border-radius: 100px;
  width: 12px;
  height: 12px;
  background: #ff9900;
}

.cart-product-list-item {
  .gallery-container .main-image {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gallery-container .main-image > .full-screen-icon {
    cursor: pointer;
    top: 0;
    right: 0;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  }

  @media only screen and (max-width: 768px) {
    .full-screen-icon {
      display: none;
    }
  }
}
