.buttons-container {
  justify-content: flex-end;
}

.footer-container {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  border-radius: 0 0 0.75rem 0.75rem;

  .pagination-count {
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: rgba(75, 75, 85, 0.6);
  }

  .pagination-arrows {
    border: 1px solid #dddddd;
    button {
      height: 28px;
      width: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;

      &.disabled {
        cursor: auto;
        &:hover {
          cursor: auto;
          background: #ffffff;
        }
        svg {
          color: #cccccc;
        }
      }

      svg {
        color: rgb(75, 75, 85);
      }

      &.left {
        border-right: 1px solid #dddddd;
      }

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(75, 75, 85, 0.05),
            rgba(75, 75, 85, 0.05)
          ),
          #ffffff;
        cursor: pointer;
      }
    }
  }
}
