button {
  &:disabled {
    background-color: #dddddd;
    color: #fff;
  }
}
@media only screen and (max-width: 768px) {
  button {
    &.mobile-icon {
      min-width: 0;
      width: 36px;
      height: 36px;
      span:first-child {
        display: none;
      }

      .btn-icon {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
      }
    }

    &.disabled {
      background: #dddddd;
      color: #fff;
    }
  }
}
