header.storefront {
  background-color: #ffffff;
  height: 90px;
  padding-top: 20px;
  padding-bottom: 20px;

  img {
    height: 100%;
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  header.storefront {
    height: 60px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
