.card-wrapper {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(75, 75, 85, 0.1);
  border-radius: 24px;
  // max-width: 1200px;
  &[data-class='group-orders'] {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }
}
.card-wrapper > :first-child {
  border-radius: 24px;
  overflow: visible;
}

@media only screen and (max-width: 600px) {
  .card-wrapper {
    border-radius: 0;
    height: 100%;
    max-width: 1200px;
  }
}

@media only screen and (max-width: 600px) {
  .card-wrapper {
    border-radius: 24px;
    margin: 0;
  }
}

@media only screen and (max-height: 500px) {
  .card-wrapper {
    height: 100vh;
    border-radius: 0;
    margin: 0;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px) and (max-height: 500px) {
  .card-wrapper {
    border-radius: 0;
    margin: 0;
  }
}

@media only screen and (min-width: 800px) and (max-height: 400px) {
  .card-wrapper {
    height: auto;
    border-radius: 0;
    margin: 0;
  }
}
