.product-page-header {
  height: 72px;
  .breadcrumb-before {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .product-page-header {
    .breadcrumb-before {
      display: inline;
    }
    .breadcrumb-after {
      display: none;
    }
  }
}
