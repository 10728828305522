.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid rgb(223, 223, 223);
  background-color: white;
  cursor: pointer;
  width: auto;
  height: 36px;
  box-shadow: 0px 1px 4px rgba(75, 75, 85, 0.1);
  padding: 0 10px;
  outline: none;
}
