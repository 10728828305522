/* Custom properties */
:root {
  --tooltip-text-color: --merch-dark-gray;
  --tooltip-background-color: white;
  --tooltip-margin: 8px;
  --tooltip-arrow-size: 6px;
  --tooltip-border-color: #fff;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 6px;
  left: -40%;
  padding: 15px 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  border: 1px solid #ddd;
  width: max-content;
  word-break: break-word;
  max-width: 200px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  line-height: 130%;
  .arrow {
    height: 10px;
    width: 10px;
    border: 1px solid #ddd;
    position: absolute;
    background: white;
  }
  &.left {
    .arrow {
      left: calc(94% + var(--tooltip-margin));
      top: 50%;
      transform: translateX(0) translateY(-50%) rotate(45deg);
      border-left: none;
      border-bottom: none;
    }
  }
  &.right {
    .arrow {
      left: calc(var(--tooltip-arrow-size) * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%) rotate(45deg);
      border-right: none;
      border-top: none;
    }
  }
  &.top {
    .arrow {
      bottom: -1px;
      left: 10%;
      transform: translateX(0%) translateY(50%) rotate(45deg);
      border-left: none;
      border-top: none;
    }
  }
  &.bottom {
    .arrow {
      bottom: calc(var(--tooltip-margin) * -1);
      left: 50%;
      transform: translateX(0%) translateY(-40%) rotate(45deg);
      border-left: none;
      border-top: none;
    }
  }

  a {
    color: #f26522;
    font-weight: 700;
    font-size: 12px;
  }
}

/* Absolute positioning */
.Tooltip-Tip.top {
  bottom: calc(var(--tooltip-margin) * 3);
}
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * 2.5);
}

// /* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
