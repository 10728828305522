footer {
  background-color: transparent;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;
  height: 120px;
  padding: 30px;
  p {
    color: rgba(75, 75, 85, 1);
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    opacity: 0.5;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    height: 100px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}
