.filter-nav {
  height: 30px;
  margin-top: -20px;
  overflow: hidden;
}

@media only screen and (max-width: 1250px) {
  .filter-nav {
    margin-top: 0px;
    height: auto;
  }
}
