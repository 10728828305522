.filter-menu-container {
  .filter-menu {
    z-index: 10;
    background: white;
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100vh;
    transition: 500ms;
    border-right: gray;
  }

  .filter-menu.active {
    z-index: 10;
    left: 0;
    transition: 500ms;
    width: 300px;
    height: 100vh;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  .sort-list-item {
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 768px) {
  .filter-menu-container {
    width: 0px;
    transition: 850ms;

    .filter-menu {
      z-index: 10;
      background: white;
      position: fixed;
      top: 0;
      left: -300px;
      width: 200px;
      min-height: 100vh;
      transition: 500ms;
    }

    .filter-menu.active {
      z-index: 10;
      left: 0;
      transition: 500ms;
      width: 250px;
      min-height: 100vh;
    }

    .menu-item-icon {
      width: 36px;
      height: 36px;
      margin-left: 12px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .logo {
      height: 60px;
    }
  }

  .sidebarMenu.active {
    width: 60px;
    transition: 350ms;

    .sidebarMenu-bars {
      display: none;
    }
  }
}
