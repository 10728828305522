.thumbnail {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  margin-top: 5px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  &.active {
    border: 1px solid rgba(75, 75, 85, 0.5);
  }

  img {
    height: auto;
    width: 100%;
  }
}
