.group-member-page-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 375px;
  width: 295px;
  .card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 60px;
      width: auto;
    }
  }

  button {
    height: 44px;
  }
}

@media only screen and (max-width: 768px) {
  .group-member-page-login-container {
    .card-wrapper {
      border-radius: 24px;
    }
  }
}
