.group-order-form-select {
  max-height: 200px;
  &.one-option {
    svg {
      visibility: hidden;
    }
  }
}

.form-select-container {
  &.h3-label-type {
    label {
      font-size: 1rem; /* 16px */
      font-weight: 500; /* 24px */
    }
  }
}

.height-fit-content {
  height: fit-content !important;
}
