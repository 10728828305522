.gallery-container {
  height: 100%;
  position: relative;

  &.expanded {
    .main-image {
      padding: 0;
      height: 100%;

      &.has-thumbnails {
        height: 100%;
      }
      img {
        max-height: 100%;
        padding: 20px;
      }
    }
    .thumbnail-container {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      bottom: 60px;
    }
  }
  .main-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &.has-thumbnails {
      padding: 60px;
      height: 85%;
    }
    img {
      max-height: 100%;
      width: auto;
    }
    .full-screen-icon {
      position: absolute;
      right: 34px;
      top: 34px;
      cursor: pointer;
    }
  }
  .thumbnail-container {
    display: flex;
    justify-content: center;
  }
  .full-screen-image {
    display: none;
    &.show {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 768px) {
  .gallery-container {
    &.expanded {
      .main-image {
        img {
          max-height: 100%;
          padding: 5px;
        }
      }
    }
    .main-image {
      padding: 30px;
    }
  }
}
